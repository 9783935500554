<template>
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="标题" prop="title">
        <a-input v-model="form.title" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="副标题" prop="subTitle">
        <a-input v-model="form.subTitle" placeholder="请输入" />
      </a-form-model-item>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="状态" prop="status" class="padding-right-sm">
            <a-radio-group v-model="status" button-style="solid">
              <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="d.value">
                {{ d.label }}
              </a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="显示顺序" prop="postSort" class="padding-left-sm">
            <a-input-number v-model="form.postSort" :min="0" style="width: 100%" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="文章分类" prop="cateId" class="padding-right-sm">
            <a-tree-select
              v-if="cateList.length"
              v-model="form.cateId"
              style="width: 100%"
              :replaceFields="replaceFields"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :tree-data="cateList"
              placeholder="请选择文章分类"
              tree-default-expand-all>
              <!-- <span v-if="key === '0-0-1'" slot="title" slot-scope="{ key, value }" style="color: #08c">
                Child Node1 {{ form.cateId }}
              </span> -->
            </a-tree-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="作者" prop="author" class="padding-left-sm">
            <a-input v-model="form.author" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-form-model-item label="图文封面" prop="images">
        <a-upload
          action=""
          list-type="picture-card"
          :file-list="fileList"
          @preview="handlePreview"
          @change="uploadImages">
          <div v-if="fileList.length < 8">
            <a-icon type="plus" />
            <div class="ant-upload-text">
              上传
            </div>
          </div>
        </a-upload>
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
          <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
      </a-form-model-item>
      <a-form-model-item label="文章内容" prop="content">
        <editor v-model="form.content" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('取消') }}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>

import * as article from '@/api/mall/article'
import { list } from '@/api/mall/articlecate'

import Editor from '@/components/Editor'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  name: 'CreateForm',
  props: {

  },
  components: {
    Editor
  },
  data() {
    return {
      loading: true,
      submitLoading: false,
      formTitle: '',
      form: {
        title: '',
        subTitle: '',
        content: '',
        author: '',
        images: [],
        postSort: 0,
        status: 1,
        cateId: undefined
      },
      replaceFields: {
        title: 'label',
        key: 'id',
        value: 'id',
        children: 'children'
      },
      cateList: [],
      statusOptions: [
        {
          label: '显示',
          value: 1
        },
        {
          label: '隐藏',
          value: 0
        }
      ],
      status: 1,
      // 上传图片
      open: false,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      rules: {
        title: [{ required: true, message: '文章标题不能为空', trigger: 'blur' }],
        subTitle: [{ required: true, message: '副标题不能为空', trigger: 'blur' }],
        cateId: [{ required: true, message: '请选择文章分类', trigger: 'blur' }],
        content: [{ required: true, message: '请输入文章内容', trigger: 'blur' }]
      }
    }
  },
  filters: {
  },
  created() {
    this.getArticleCate()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    // 过去文章分类
    getArticleCate() {
      this.loading = false
      this.cateList = [{
        'id': 1,
        'pid': 0,
        'label': '热门推荐',
        'image': '',
        'sort': 0,
        'status': 1,
        'children': [
          {
            'id': 20,
            'pid': 1,
            'label': '新闻动态',
            'image': '',
            'sort': 0,
            'status': 1
          }
        ]
      },
      {
        'id': 2,
        'pid': 0,
        'label': 'APP问题',
        'image': '',
        'sort': 0,
        'status': 1,
        'children': []
      }]
      return
      list.then(res => {

      }).catch(err => {

      })
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        title: '',
        subTitle: '',
        content: '',
        author: '',
        images: [],
        postSort: 0,
        status: 1,
        cateId: undefined
      }
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.formTitle = '添加文章'
      this.reset()
      this.open = true
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      const id = row ? row.id : ids
      this.open = true
      this.formTitle = '修改文章'
      return
      article.detail({ id: id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改文章'
      })
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    // 上传图片
    uploadImages({ fileList }) {
      // console.log('fileList', fileList)
      this.fileList = fileList
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.postId !== undefined) {
            // console.log('form', this.form)
            return
            updatePost(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            // console.log('form', this.form)
            // console.log('上传的封面图', this.fileList)
            this.form.images = this.fileList
            return
            addPost(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
